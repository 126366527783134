export class Voucher {
  constructor({
    id,
    descricao,
    tipo_desconto,
    limitar_por_usuario,
    data_inicio,
    data_fim,
    quantidade,
    valor,
    status,
    tipo_voucher,
    valor_minimo,
    unidades,
    codigo,
    produtos,
  } = {
      id: null,
      descricao: '',
      tipo_desconto: 'Percentual',
      limitar_por_usuario: true,
      data_inicio: null,
      data_fim: null,
      quantidade: null,
      valor: null,
      status: true,
      tipo_voucher: 0,
      valor_minimo: 0,
      unidades: [],
      codigo: null,
      produtos: [],
    }) {
    this.id = id
    this.descricao = descricao
    this.tipo_desconto = tipo_desconto
    this.limitar_por_usuario = limitar_por_usuario
    this.data_inicio = data_inicio
    this.data_fim = data_fim
    this.quantidade = quantidade
    this.valor = valor
    this.status = status
    this.tipo_voucher = tipo_voucher
    this.valor_minimo = valor_minimo
    this.unidades = unidades
    this.codigo = codigo
    this.produtos = produtos
  }
}

export function createVoucher (data) {
  return Object.freeze(new Voucher(data))
}
