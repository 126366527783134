<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    persistent
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title
          v-if="!loading"
          class="headline"
        >
          {{ isEditing ? 'Editando Voucher' : 'Cadastrando Voucher' }}
        </v-toolbar-title>
        <v-toolbar-title
          v-else
          class="headline"
        >
          Voucher
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <base-loading v-if="loading" />
        <v-window
          v-else
          v-model="step"
        >
          <v-window-item :value="1">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <crud-card title="Identificação do Voucher">
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                  >
                    <v-text-field
                      v-model="descricao"
                      label="Descrição"
                      dense
                      rounded
                      outlined
                      :rules="[rules.required,rules.maxLength( 255, 'A descrição atingiu o tamanho máximo' )]"
                      :maxlenght="255"
                      :counter="255"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="codigo"
                      label="Palavra-chave Cupom"
                      dense
                      rounded
                      outlined
                      :rules="couponRules"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-comment-question-outline
                            </v-icon>
                          </template>
                          <span>
                            Insira a palavra-chave do cupom sem espaços<br>
                            e utilizando apenas letras maiúsculas.<br>
                            Exemplos válidos: CUPOM10, CUPOM_20
                          </span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </crud-card>
              <crud-card title="Parâmetros de Validade e Disponibilidade">
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                    >
                      <v-sheet
                        class="pa-4"
                        elevation="0"
                        outlined
                        rounded="xl"
                      >
                        <v-radio-group
                          v-model="tipo_voucher"
                          class="mt-0"
                          @change="clearProdutos()"
                        >
                          <template v-slot:label>
                            <span class="font-weight-bold">Tipo de Voucher</span>
                          </template>
                          <v-radio
                            v-for="(item, index) in TipoVoucher.values()"
                            :key="index"
                            :label="TipoVoucher.getDescription(item)"
                            :value="item"
                          />
                        </v-radio-group>
                      </v-sheet>
                    </v-col>
                    <v-col cols="9">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <data-time-picker
                            v-model="data_inicio"
                            :label="'Inicio *'"
                            :data-max="data_fim"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <data-time-picker
                            v-model="data_fim"
                            :label="'Fim *'"
                            :data-min="data_inicio"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="3"
                          md="3"
                        >
                          <v-text-field
                            v-model="quantidade"
                            v-mask="'####'"
                            label="Quantidade"
                            dense
                            rounded
                            outlined
                            :rules="rulesQuantidade"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="9"
                          md="9"
                        >
                          <v-autocomplete
                            v-model="unidades"
                            :items="unidadesNegocio"
                            item-text="nome_fantasia"
                            item-value="id"
                            chips
                            dense
                            multiple
                            attach
                            deletable-chips
                            small-chips
                            label="Unidade de negócio*"
                            clearable
                            :loading="!unidades"
                            autocomplete="off"
                            validate-on-blur
                            outlined
                            rounded
                            :rules="[rulesUnidades]"
                            @change="clearProdutos()"
                            @blur="() => {
                              LOAD_PRODUTOS()
                            }"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip
                                v-if="index < 4"
                                rounded
                                small
                                class="caption"
                              >
                                <span>
                                  {{ item.nome_fantasia }}
                                </span>
                              </v-chip>
                              <span
                                v-if="index === 4"
                                class="grey--text caption"
                              >
                                (+{{ unidades.length - 4 }} outros)
                              </span>
                            </template>
                            <template
                              slot="item"
                              slot-scope="data"
                            >
                              <v-list-item-content
                                style="max-height: 100px"
                                class="overflow-auto"
                              >
                                <v-list-item-title>{{ data.item.nome_fantasia }}</v-list-item-title>
                                <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </crud-card>
              <crud-card title="Parâmetros de Aplicação dos Descontos">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-sheet
                        class="pa-4"
                        elevation="0"
                        outlined
                        rounded="xl"
                      >
                        <v-radio-group
                          v-model="limitar_por_usuario"
                        >
                          <template v-slot:label>
                            <span class="font-weight-bold">Limitar uso do Cupom</span>
                          </template>
                          <v-radio :value="false">
                            <template v-slot:label>
                              <div><strong class="primary--text">Vários Cupons por Cliente</strong></div>
                            </template>
                          </v-radio>
                          <v-radio :value="true">
                            <template v-slot:label>
                              <div><strong class="error--text">Somente um Cupom por Cliente</strong></div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-sheet>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="2"
                    >
                      <v-sheet
                        class="pa-4"
                        elevation="0"
                        outlined
                        rounded="xl"
                      >
                        <v-radio-group
                          v-model="tipo_desconto"
                          :rules="[rules.required]"
                        >
                          <template v-slot:label>
                            <span class="font-weight-bold">Tipo de Desconto</span>
                          </template>
                          <v-radio
                            v-for="(item, index) in TipoDescontoVoucher.values()"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </v-radio-group>
                      </v-sheet>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-row>
                        <v-col>
                          <base-vuetify-money
                            v-model="valor"
                            :options="money"
                            maxlength="10"
                            label="Valor"
                            validate-on-blur
                            dense
                            outlined
                            rounded
                            :rules="[rules.required, validaValor]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <base-vuetify-money
                            v-model="valor_minimo"
                            :options="money"
                            maxlength="10"
                            label="Valor Mínimo para Aplicação"
                            validate-on-blur
                            dense
                            outlined
                            rounded
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </crud-card>
            </v-form>
          </v-window-item>
          <v-window-item :value="2">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="7"
                >
                  <v-autocomplete
                    ref="produtos"
                    v-model="tmpProdutos"
                    :items="produtosDialog"
                    item-text="nome"
                    chips
                    dense
                    multiple
                    return-object
                    attach
                    :filter="filterProduto"
                    deletable-chips
                    small-chips
                    label="Produtos"
                    clearable
                    :loading="!listProdutos"
                    no-data-text="Nenhum produto disponível"
                    autocomplete="off"
                    hide-details
                    validate-on-blur
                    outlined
                    rounded
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        v-if="index < 5"
                        rounded
                        small
                        class="caption"
                      >
                        <span>
                          {{ item.nome }}
                        </span>
                      </v-chip>
                      <span
                        v-if="index === 5"
                        class="grey--text caption"
                      >
                        (+{{ tmpProdutos.length - 5 }} outros)
                      </span>
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.codigo }}</v-list-item-subtitle>
                        <span
                          v-if="data.item.disabled"
                          color="red"
                        ><b><i>Esse produto já está cadastrado em uma
                          campanha/oferta</i></b></span>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="auto"
                >
                  <v-btn
                    color="success"
                    class="mr-auto"
                    rounded
                    small
                    fab
                    @click="adicinarProduto"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <span
                    v-if="produtosTable.length === 0"
                    style="color:red;"
                    class="font-weight-light"
                  >
                    *Deve-se adicionar pelo menos um produto na regra
                  </span>

                  <v-text-field
                    v-else
                    v-model="search"
                    append-icon="mdi-magnify"
                    color="primary"
                    label="Pesquisar"
                    outlined
                    rounded
                    dense
                    elevation="0"
                    hide-details
                    clearable
                    clear-icon="mdi-close"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-data-table
                    :headers="[
                      { text: '', value: 'actions', sortable: false },
                      { text: 'Código', value: 'codigo' },
                      { text: 'Nome', value: 'nome' },
                      { text: 'Marca', value: 'marca' },
                      { text: 'Fornecedor', value: 'fornecedor' },
                    ]"
                    :search="search"
                    height="300"
                    fixed-header
                    :items="produtosTable"
                    :items-per-page="10"
                    class="elevation-1"
                    no-data-text="Nenhum produto selecionado"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>
                          <v-icon
                            color="error"
                            @click="removeProduto(item)"
                          >
                            mdi-trash-can-outline
                          </v-icon>
                        </td>
                        <td>{{ item.codigo }}</td>
                        <td>{{ item.nome }}</td>
                        <td>{{ item.marca }}</td>
                        <td>{{ item.fornecedor }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          rounded
          right
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          v-if="step === 2"
          color="primary"
          rounded
          @click="step = 1"
        >
          <v-icon
            dark
            left
          >
            mdi-arrow-left-bold
          </v-icon>
          Anterior
        </v-btn>
        <v-btn
          v-if="(!loading && tipo_voucher !== TipoVoucher.PRODUTOS_SELECIONADOS) || step === 2"
          color="success"
          right
          rounded
          @click="enviar"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Salvar
        </v-btn>
        <v-btn
          v-else
          color="primary"
          right
          rounded
          @click="proximoStep"
        >
          <v-icon
            dark
            left
          >
            mdi-arrow-right-bold
          </v-icon>
          Proximo
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import VoucherStore, { SUBMIT, CANCEL, LOAD_PRODUTOS } from '@/store/modules/forms/voucher'
  import { mapState, mapActions } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import moment from 'moment'
  import { TipoDescontoVoucher, TipoVoucher } from '../../utils/constants'

  export default {
    name: 'VoucherDialog',
    data () {
      return {
        moment,
        TipoDescontoVoucher,
        TipoVoucher,
        rules,
        data_inicial: new Date().toISOString(),
        data_final: new Date().toISOString(),
        valid: true,
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        couponRules: [
          value => !!value || 'O campo é obrigatório.',
          value => !/\s/.test(value) || 'Não inclua espaços.',
          value => /^[A-Z0-9_-]*$/.test(value) || 'Apenas letras maiúsculas, números e sublinhados',
        ],
        rulesQuantidade: [
          value => !!value || 'O campo é obrigatório.',
          value => value > 0 || 'O valor deve ser maior que 0',
        ],
        step: 1,
        tmpProdutos: [],
        search: '',
      }
    },
    computed: {
      ...mapState('form/voucher', ['loading', 'isEditing', 'dialog', 'unidadesNegocio', 'listProdutos']),
      ...mapFields('form', [
        'data.descricao',
        'data.tipo_desconto',
        'data.limitar_por_usuario',
        'data.data_inicio',
        'data.data_fim',
        'data.quantidade',
        'data.valor',
        'data.status',
        'data.tipo_voucher',
        'data.valor_minimo',
        'data.unidades',
        'data.codigo',
        'data.produtos',
      ]),
      produtosDialog () {
        if (!this.listProdutos) return []
        return this.listProdutos.filter((produto) => !this.produtos.some((prod) => prod.id === produto.id))
      },
      produtosTable () {
        if (!this.listProdutos) return []
        return this.listProdutos.filter((produto) => this.produtos.some((prod) => prod.id === produto.id))
      },
    },
    created () {
      if (!this.$store.state.form.voucher) { this.$store.registerModule(['form', 'voucher'], VoucherStore) }
      if (this.isEditing) {
        this.data_inicial = this.data_inicio
        this.data_final = this.data_fim
      }
    },
    methods: {
      ...mapActions('form/voucher', [SUBMIT, CANCEL, LOAD_PRODUTOS]),
      cancel () {
        this.step = 1
        this.CANCEL()
      },
      enviar () {
        if (this.step === 2 && this.produtos.length === 0) {
          this.$refs.produtos.validate()
          return
        }
        if (this.step === 1 && !this.$refs.form.validate()) {
          return
        }
        if (this.moment(this.data_inicio) > this.moment(this.data_fim)) {
          this.data_inicio = null
          this.data_fim = null
          return
        }
        this.SUBMIT()
      },
      rulesUnidades (value) {
        if (!value) return 'Selecione ao menos uma unidade de negócio'
        return value.length > 0 || 'Selecione ao menos uma unidade de negócio'
      },
      formatData (dataInicio = new Date(), dataFim = new Date()) {
        return `${this.moment(dataInicio).format('DD/MM/YYYY')} - ${this.moment(dataFim).format('DD/MM/YYYY')}`
      },
      validaValor (value) {
        if (!value) return 'O campo é obrigatório'
        let dado = value.replace('.', '')
        dado = dado.replace(',', '.')
        if (Number(dado) <= 0) {
          return 'O valor deve ser maior que 0'
        }
        return true
      },
      adicinarProduto () {
        if (!this.tmpProdutos || !this.produtos) return
        const setProdutos = new Set(this.produtos)
        this.tmpProdutos.forEach((produto) => {
          setProdutos.add({
            id: produto.id,
            codigo: produto.codigo,
            nome: produto.nome,
          })
        })

        this.produtos = Array.from(setProdutos)
        this.tmpProdutos = []
      },
      removeProduto (produto) {
        this.produtos = this.produtos.filter((prod) => prod.id !== produto.id)
      },
      clearProdutos () {
        this.produtos = []
      },
      proximoStep () {
        if (this.moment(this.data_inicio) > this.moment(this.data_fim)) {
          this.data_inicio = null
          this.data_fim = null
          return
        }
        if (!this.$refs.form.validate()) {
          return
        }
        this.step = 2
      },
      filterProduto (item, consulta, nome) {
        if (nome.toLowerCase().includes(consulta.toLowerCase())) return true
        if (item.codigo.includes(consulta)) return true
        return false
      },
    },
  }
</script>
