import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Voucher } from '@/models/Voucher'
import Swal from 'sweetalert2'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const CANCEL = 'CANCEL'
export const RESET_STATE = 'RESET_STATE'
export const LOAD_UNIDADES_NEGOCIO = 'LOAD_UNIDADES_NEGOCIO'
export const LOAD_PRODUTOS = 'LOAD_PRODUTOS'

const getDefaultState = () => {
  return {
    dialog: false,
    loading: true,
    isEditing: false,
    unidadesNegocio: [],
    listProdutos: null,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, rootState }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false, dialog: true })
    await dispatch(LOAD_UNIDADES_NEGOCIO)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('voucher', id).then(response => {
        dispatch('form/BOOTSTRAP_FORM', { model: new Voucher(response.data) }, { root: true })
      })
      dispatch(LOAD_PRODUTOS)
    } else {
      dispatch('form/BOOTSTRAP_FORM', { model: new Voucher() }, { root: true })
    }
    commit(SET_STATE, { loading: false })
  },

  async [CANCEL] ({ commit }) {
    commit(SET_STATE, { dialog: false, loading: true })
    commit('form/RESET_STATE', null, { root: true })
  },

  async [LOAD_UNIDADES_NEGOCIO] ({ commit, rootState }) {
    commit(SET_STATE, {
      unidadesNegocio: await api.listEntidade(`${rootState.user.roles[0].slug}/unidadesNegocio`),
    })
  },

  async [SUBMIT] ({ commit, dispatch, state, rootState }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };
        (() => {
          return state.isEditing
            ? api.salvarEntidade(form, 'voucher', form.id)
            : api.cadastrarEntidade(form, 'voucher')
        })().then(
          data => {
            commit('SET_STATE', { dialog: false })
            commit('form/RESET_STATE', null, { root: true })
            Swal.fire({
              title: state.isEditing
                ? 'Voucher atualizada com sucesso!'
                : 'Voucher cadastrada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            commit('SET_STATE', { loading: true })
            dispatch('vouchers/LOAD_BATCH', null, { root: true })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção...',
              text: error.message,
            })
          },
        )
      },
    })
  },
  async [LOAD_PRODUTOS] ({ commit, rootState }) {
    const unidades = rootState.form.data.unidades
    commit(SET_STATE, { listProdutos: null })
    const allProdutos = await api.getProdutosUnidades({ unidades })
    const listProdutos = allProdutos.filter((p) => {
      if (p.disponibilidade === 2) {
        return false
      }
      if (unidades.every((unidade) => p.unidades.includes(unidade))) {
        return true
      }
      return false
    })
    commit(SET_STATE, { listProdutos })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
