<template>
  <div class="px-4 content">
    <base-loading
      v-if="loading"
    />
    <crud-list
      v-else
      v-model="vouchers"
      :headers="headers"
      striped
      export-pdf-xlsx
      :permission="'list_voucher'"
      :actions="actions"
      :slots="[
        'item.data_inicio',
        'item.data_fim',
        'item.status',
        'item.unidades',
        'item.limitar_por_usuario',
        'item.valor',
        'item.valor_minimo',
      ]"
      :item-height="$vuetify.breakpoint.lg ? 'calc(82vh - 263px)' : 'calc(81vh - 195px)'"
    >
      <template
        v-if="$store.getters.hasPermission('inserir_voucher')"
        v-slot:btnCadastro
      >
        <v-col>
          <v-btn
            block
            class="text-none text-white"
            color="primary"
            title="Cadastrar novo voucher"
            rounded
            @click="NEW_VOUCHER"
          >
            <v-icon
              dark
              left
            >
              mdi-file-plus
            </v-icon>
            Cadastrar
          </v-btn>
        </v-col>
      </template>
      <template v-slot:[`item.data_inicio`]="{ item }">
        <span>
          {{ moment(item.data_inicio).format('DD/MM/YYYY - HH:mm') }}
        </span>
      </template>
      <template v-slot:[`item.data_fim`]="{ item }">
        <span>
          {{ moment(item.data_fim).format('DD/MM/YYYY - HH:mm') }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          v-model="item.status"
          dense
          inset
          color="success"
          :loading="false"
          hint="Ativar/Desativar o voucher!"
          @change="UPDATE_STATUS_VOUCHER({item})"
        />
      </template>
      <template v-slot:[`item.unidades`]="{ item }">
        <span>
          {{ concatUnidades(item.unidades) }}
        </span>
      </template>
      <template v-slot:[`item.limitar_por_usuario`]="{ item }">
        <span>
          {{ item.limitar_por_usuario ? 'Sim' : 'Não' }}
        </span>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <span>
          {{ formatterValor(item.valor, item.tipo_desconto) }}
        </span>
      </template>
      <template v-slot:[`item.valor_minimo`]="{ item }">
        <span>
          {{ Number(item.valor_minimo).toLocaleString('pt-BR',{ style: "currency", currency: "BRL" }) }}
        </span>
      </template>
    </crud-list>
    <voucher-dialog />
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card max-height="650">
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title> Vouchers gerados </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <base-loading
          v-if="!cupons.length"
        />

        <v-card-text v-else>
          <v-text-field
            v-model="search"
            class="mb-4"
            append-icon="mdi-magnify"
            label="Pesquisar"
            outlined
            rounded
            dense
            single-line
            hide-details
          />
          <v-simple-table
            fixed-header
            height="400px"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Pedido</th>
                <th>Nome do Produtor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item, indexCupom in dadosTable"
                :key="item.id"
              >
                <td>{{ indexCupom + 1 }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.pedido_id || '' }}</td>
                <td>{{ item.nome_produtor || '' }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            rounded
            right
            @click="closeDialog"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import { BOOTSTRAP, NEW_VOUCHER, EDIT_VOUCHER, DELETE_VOUCHER, UPDATE_STATUS_VOUCHER, LISTAR_CUPONS, DESTRUIR_CUPONS } from '@/store/modules/vouchers'
  import { TipoDescontoVoucher } from '@/utils/constants'
  import VoucherDialog from './VoucherDialog'
  import store from '@/store'

  export default {
    components: {
      VoucherDialog,
    },
    data () {
      return {
        TipoDescontoVoucher,
        headers: [
          { align: 'center', sortable: false, groupable: false, floatingfilter: false, text: 'Ações', value: 'actions', width: 'auto', report: false },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Status', value: 'status', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Descrição', value: 'descricao', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Palavra chave Cupom', value: 'codigo', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Limita 1 por Usuário', value: 'limitar_por_usuario', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Tipo Aplicação', value: 'tipo_voucher', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Tipo desconto', value: 'tipo_desconto', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Valor/Percentual', value: 'valor', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Valor Mínimo', value: 'valor_minimo', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Inicio', value: 'data_inicio', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Fim', value: 'data_fim', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Quantidade', value: 'quantidade', width: 'auto', report: true, styleReport: { halign: 'left' } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Unidades', value: 'unidades', width: 'auto', report: true, styleReport: { halign: 'left' } },

        ],
        actions: [
          {
            title: 'Editar Voucher',
            color: 'primary',
            click: item => this.EDIT_VOUCHER({ item }),
            icon: 'mdi-pencil',
            permission: 'editar_voucher',
          },
          {
            title: 'Excluir Voucher',
            color: 'error',
            click: (item) => this.DELETE_VOUCHER({ item }),
            icon: 'mdi-delete',
            permission: 'excluir_voucher',
          },
          {
            title: 'Visualizar Voucher',
            color: 'info darken-3',
            click: (item) => this.exibirVoucher(item),
            icon: 'mdi-eye',
            permission: 'list_voucher',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        dialog: false,
        search: '',
      }
    },
    computed: {
      ...mapState('vouchers', ['vouchers', 'loading', 'cupons']),

      dadosTable () {
        if (!this.search) return this.cupons
        return this.cupons.filter(cupom => {
          const searchLower = this.search.toLowerCase()
          return (cupom.pedido_id && cupom.pedido_id.toString().includes(this.search)) ||
            (cupom.nome_produtor && cupom.nome_produtor.toLowerCase().includes(searchLower))
        })
      },
    },
    created () {
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('vouchers', [BOOTSTRAP, NEW_VOUCHER, EDIT_VOUCHER, DELETE_VOUCHER, UPDATE_STATUS_VOUCHER, LISTAR_CUPONS, DESTRUIR_CUPONS]),
      mudarStatus (item) {
        console.log(item)
      },
      concatUnidades (unidades) {
        if (!unidades) return ''
        return unidades.map(unidade => unidade.nome_fantasia).join(', ')
      },
      exibirVoucher (item) {
        this.LISTAR_CUPONS({ id: item.id })
        this.dialog = true
      },
      closeDialog () {
        this.dialog = false
        this.DESTRUIR_CUPONS()
      },
      formatterValor (valor = null, tipo = null) {
        if (!valor) return ''
        const valorFormatado = Number(valor).toLocaleString('pt-BR')
        if (tipo === this.TipoDescontoVoucher.PERCENTUAL) return `${valorFormatado}%`
        return `R$ ${valorFormatado}`
      },
    },

  }
</script>
